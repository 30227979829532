.PatientConsole{
    padding: 10rem;
    position: relative;
}

.PatientConsole .LogoutButton{
    top: 50px;
    left: 50px;
    position: absolute;
    color: white;
    background: darkgray;
    font-weight: bold;
    font-size: 1rem;
    padding: 1rem 1rem;
    border: none;
    min-width: 120px;
    border-radius: 10px;
    outline: none;
    z-index: 2;
}

.PatientConsole .LogoutButton:hover{
    cursor: pointer;
    background-color: rgba(169, 169, 169, 0.507);
}


.PatientConsole .userStatus {
    margin-bottom: 3rem;
    text-align: center;
}

.PatientConsole .StartChattingButton {
    color: white;
    background: #DE6926;
    font-weight: bold;
    font-size: 1rem;
    padding: 1rem 2rem;
    border: none;
    min-width: 150px;
    border-radius: 10px;
    outline: none;
    z-index: 2;
}

.PatientConsole .StartChattingButton:hover{
    background: rgba(222, 105, 38, 0.5);
    cursor: pointer;
}

.PatientConsole .modalButtonForm{
    display: flex;
    flex-direction: row;
    gap: 2rem;
    margin: auto;
    width: 30%;
    justify-content: center;
    margin-top: 1.5rem;
}

.PatientConsole .modalButtonForm>button:hover{
    background: rgba(222, 105, 38, 0.5);
    cursor: pointer;
}

.PatientConsole .modalButtonForm>button{
    color: white;
    background: #DE6926;
    font-weight: bold;
    font-size: 1rem;
    padding: 1rem 2rem;
    border: none;
    min-width: 150px;
    border-radius: 10px;
    outline: none;
    z-index: 2;
}