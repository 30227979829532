.Box{
    position: relative;
    box-sizing: border-box;
    width: 17rem;
    height: 20rem;
    margin: 0;
}

.Box .Banner{
    width: 100%;
    height: auto;
    bottom: 0;
    position: absolute;
    box-sizing: border-box;
    background: radial-gradient(98.05% 261.61% at 1.95% 3.59%, #214D75 0%, rgba(31, 90, 142, 0.4) 0.01%);
    opacity: 0.9;
    backdrop-filter: blur(42px);
    border-radius: 5px;
}

.Box .Banner>p{
    color: white;
    padding: 0.6rem 1rem;
    font-size: 1.2rem;
    font-weight: 700;
}