.Footer{

    background: #214D75;
    clear: both;
    bottom: 0;
    width: 100vw;
    position: relative;
    margin-top: auto;
    display: grid;
    grid-template-columns: 40% 30% 30%;
    padding: 1.3rem 1.5rem;
    box-sizing: border-box;
    white-space: pre-wrap;
}

.Footer .Info{
    list-style: none;
    justify-self: center;
    align-self: top;
    padding: 0;
    margin: 0;

}

.Footer .Info li{
    display: flex;
    align-items: center;
    padding-left: 1rem;
}

.Footer .Info .Title{
    color: white;
}

.Footer .Info .Subtitle{
    color: white;
 
}

.Footer .Info .Subtitle li{
    margin: 0.2rem auto;
}

.Footer .Links, .Footer .Admin{
    list-style: none;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-self:start;
    align-self: top;
    margin: 0;
}

.Footer .Links .Link{
    text-decoration: none;
    color: white;
    font-weight: bold;
    font-size: 1.3rem;
    margin: 0.5rem;
    text-align: left;
}

.Footer .Logos{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    align-items: center;
    justify-items: center;
    grid-template-rows: repeat(1,1fr);
    gap: 1rem;
}

.Footer .Logos img{
    width: 100px;
    background-color: white;
}

.Footer .Logos img:not(.NoPadding){
    padding: 10px;
}

@media (max-width: 900px){
    .Footer{
        grid-template-columns: 1fr 1fr;
        grid-auto-flow: row;
        row-gap: 1rem;
    } 
    .Footer .Logos{
        grid-column: 1 / -1;
        grid-template-columns: repeat(4, 1fr);
    }

    .Footer .Logos img{
        width: 120px;
    }
}

@media (max-width: 700px){
    .Footer .Logos{
        grid-template-columns: repeat(2, 1fr);
    }
}