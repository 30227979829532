.AboutPage{
    display: flex;
    flex-direction: column;
    padding: 4rem 7rem;
    justify-content: space-between;
}

.AboutPage .Info{
    margin: auto;
    margin-bottom: 2rem;
    width: 60vw;
    max-width: 600px;
  
}

.AboutPage .Info>img{
    margin: auto;
    transform: scale(1.2);
}

.AboutPage .Info>h1{
    margin-top: 0;
    color: #214D75;
    text-align: left;
    font-size: 4rem;
}

.AboutPage .Info>p{
    font-size: 1.25rem;
    font-weight: 550;
    color: gray;
    text-align: left;
}

.AboutPage .Team{
    display: grid;
    margin: 0;
    grid-template-columns: repeat(auto-fit, minmax(250px,auto));
    grid-auto-flow: row;
    column-gap: 5rem;
    row-gap: 5rem;
    justify-content: center;
}


.AboutPage .Team .Member{
    background-color: white;
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 4px 2px 13px 5px rgba(0,0,0,0.25);
  
}


.AboutPage .Team .Member>img{
    width: 170px;
    height: 210px;
    object-fit: cover;
    border-radius: 4px;
}

.AboutPage .Team .Member>p{
    color: gray;
    margin:0;
    padding: 0;
}

@media (max-width: 1300px){
    .AboutPage {
        flex-direction: column;
    }
    .AboutPage .Info{
        margin: auto;
        margin-bottom: 2rem;
        width: 60vw;
    }

    .AboutPage .Info>h1{
        text-align: center;
    }

    .AboutPage .Team{
        
    }
}