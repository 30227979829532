.AuthContainer{
    margin: auto;
    width: 50%;
    min-width: 50rem;
    height: auto;
    margin-top: 5%;
    margin-bottom: 5%;
    box-shadow: 1px 4px 13px 6px rgba(163,163,163,0.77);
    border-radius: 10px;
    background-color: white;
    display: grid;
    column-gap: 1rem;
    grid-template-columns: 1.3fr 1fr;
}

.AuthContainer .Image{
    width: 80%;
    grid-column-start: 1;
    grid-row: 1;
    margin: auto
}