.AuthForm{
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
    margin-right: 4rem;
    grid-column-start: 2;
    padding: 2rem 0rem 2rem 1rem;
    box-sizing: border-box;
}

.AuthForm .Title {
    text-align: left;
    color: #214D75;
    font-size: 2.3rem;
    margin: 0
}

.AuthForm > form{
    width: 100%;
    margin-top: 0.5rem;
}

.AuthForm .Input{
    width: 100%;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.AuthForm .Switch{
    margin-top: 1.6rem;
    color: darkgray;
    font-weight: 500;
    font-size: 1.1rem;
}

.AuthForm .Switch>a{
    text-decoration: underline;
    color: darkgray;
}