.Layout{
    height: 100%;
    display: grid;
    grid-template-rows: 9.1rem 1fr auto;
    grid-template-areas: "nav"
                         "main"
                         "footer";
}

.Content{

    grid-area: main;

}

body{
    background: #F2F6F8;
}

.Nav{
    grid-area: nav;
}

.Footer{
    grid-area: footer;
}

@media (max-width: 900px){
    .Layout{
        grid-template-rows: 5.1rem 1fr auto;
    }
}