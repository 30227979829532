.ToolbarItem{
    margin: 0;
    box-sizing: border-box;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    width: 100%

}
.ToolbarItem a{
    font-family: 'Helvetica';
    font-weight: bold;
    text-decoration: none;
    height: 100%;
    font-size: 1.1rem;
    border-bottom: 4px solid transparent;
    box-sizing: border-box;
    color: black;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}


.ToolbarItem a:hover, .ToolbarItem .Active{
    color: #214D75;
    border-bottom: 4px solid #214D75;
}

@media(max-width: 900px){
    .ToolbarItem{
        height: 2rem;
        margin: 1rem 1rem; 
    }

    .ToolbarItem a{
        font-size: 1.5rem;
        height: 3rem;
    }

    .ToolbarItem a.Active{
        background-color: #214D75;
        color: white;
        border-bottom: 4px solid transparent;
    }

    .ToolbarItem a:hover{
        color: #214D75;
        border-bottom: 4px solid transparent;
    }
}

