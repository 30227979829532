.ProstateCancerPage{
    width: 100%;
    box-sizing: border-box;
}
.ProstateCancerPage .Banner{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 7%;
    padding-top: 3rem;
    padding-bottom: 4rem;
    box-sizing: border-box;
    margin: 0;
}


.ProstateCancerPage .Banner .Title{
    color: #214D75;
    font-size: 3rem;
    text-align: left;
    margin-top: 0;
    box-sizing: border-box;
}

.ProstateCancerPage .Banner .Info{
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: 1.3rem;
    font-weight: 600;
}

.ProstateCancerPage .Banner .Info>p{
    box-sizing: border-box;
    margin: 0.2rem 0;
    text-align: left;
}
