.NavigationBarTitle{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.NavigationBarTitle h1 > span {
    font-size: 3rem;
}

.NavigationBarTitle h1{
    /*font-family: Helvetica Rounded;*/
    margin: 0;
    box-sizing: border-box;
    margin-left: 20px;
    font-style: normal;
    font-weight: bold;
    font-size: 2.6rem;
    color: white;
    height: 80%;
}
