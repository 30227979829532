.Sidedrawer{
    position: fixed;
    background-color: white;
    left: 0;
    top: 5.1rem;
    width: 20rem;
    max-width: 70%;
    height: 100%;
    z-index: 300;
    box-sizing: border-box;
    transition: transform 0.3s ease-out;
}


.Open {
    transform: translateX(0);
}

.Close {
   transform: translateX(-101%);
}

@media(min-width: 900px){
    .Sidedrawer{
        display: none;
    }
}