.UnderConstruction{
    height: 25vh;
    min-height: 340px;
    min-width: 340px;
    width: 25vw;
    margin: 5rem auto;
}


.UnderConstruction>h2{
    color: #214D75;
    margin: 0;
    padding: 0;
 
}