.SupportGroupContainer{
    position: relative;
}

.SupportGroup{
    width: 100%;
    background: white;
    display: grid;
    grid-template-columns: [col-start] 1fr [col-end col-start] 2.5fr [col-end col-start] 2fr [col-end];
    grid-template-rows: repeat(7,auto);
    padding: 2rem;
    box-sizing: border-box;
    padding-right: 5%;
    column-gap: 3%;
    row-gap: 1rem;
    border-bottom: 2.5px solid rgba(0, 0, 0, 0.2);
    justify-items: start;
    align-items: center;                
    height: auto;
}

.SupportGroup .Gap{
    grid-row: 0;
    grid-column: col-start 1 / col-end -1;
    color: red;
}

.SupportGroup h2{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

.SupportGroup .Title{
    color: black;
    grid-column: col-start 2 / col-end 3;
    grid-row: 2;
    text-align: start;
    font-size: 1.5rem;
    font-weight: 600;
  
}

.SupportGroup .Summary{
    color: black;
    grid-column: col-start 2 / col-end 3;
    grid-row:3;
    text-align: start;
}

.SupportGroup .Fees {
    grid-column: col-start 2/ col-end -1;
    grid-row: 4;
}

.SupportGroup .Perks {
    grid-column: col-start 2/ col-end 3;
    grid-row: 5;
}



.SupportGroup label, .SupportGroup p{
    display: inline;
    font-size: 1.1rem;
    margin: 0;
    padding: 0;

}

.SupportGroup label{
    font-weight: bold;
}