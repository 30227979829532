@import '~hamburgers/_sass/hamburgers/hamburgers.scss';

.Button{
    display: none;
    outline: none;
}

@media(max-width: 900px){
    .Button{
        display: inline;
    }
}