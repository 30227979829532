.Image {
    box-sizing: border-box;
    width: 90%;
    max-width: 250px;
    border-radius: 15px;
    margin: auto;
    grid-column: 1 / 2;
    grid-row: 2 / -1;
}

@media(max-width: 900px){
    .Image{
        grid-row: 1;
        grid-column: 1/-1;
    }
}