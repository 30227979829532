.ContactPage{
}

.ContactPage .Title{
    color: black;
    margin: auto;
    margin-top: 4rem;
    margin-bottom: 2rem;
    font-size: 3rem;
}
