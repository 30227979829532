.SearchContainer{
    width: 100%;
    height: auto;
    background: white;
    position: relative;
    margin-top: max(3rem, 4vw);
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2rem;

}
.SearchContainer .Badge{
    position: absolute;
    box-sizing: border-box;
    top: -55px;
    left: 5%;
    background-color: white;
    padding: 1rem;
    border-radius: 10px;
}

.SearchContainer p{
    font-weight: bold;
    margin-right: 5%;
    font-size: 1.2rem;
}

.SearchContainer .Form{
    width: 70%;
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;   
}

.SearchContainer .Input{
    margin: 1rem 1rem;
    box-sizing: border-box;
    min-width: 11rem;
    flex-grow: 1;
}

.SearchContainer .HealthStatusContainer{
    flex-grow: 1;
    margin: 1rem 1rem;
    box-sizing: border-box;
}

.SearchContainer .HealthStatusContainer .HelperText {
    font-size: 0.9rem;
    color: rgb(106,106,106);
    font-weight: 400;
}

.SearchContainer .HealthStatusContainer .Label{
    color: rgb(106,106,106);
    font-size: 1.1rem;
}


.SearchContainer .SearchButton{
    color: white;
    background: #DE6926;
    font-weight: bold;
    font-size: 1rem;
    padding: 1rem 2rem;
    border: none;
    border-radius: 10px;
    margin-left: 5%;
    outline: none;
}

.SearchContainer .SearchButton:hover{
    background: rgba(222, 105, 38, 0.5);
    cursor: pointer;
}

.SearchButton.Disabled{
    background: rgba(222, 105, 38, 0.5);
    pointer-events: none;  /* Makes button non clickable*/
}
.SearchContainer .InputLabel{
    margin: 0;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    text-align: center;
}


@media(max-width: 64rem){
    .SearchContainer>p{
        display: block;
        width: 100%;
        margin-top:0
    }

    .SearchContainer{
        flex-wrap: wrap;
    }
}

@media (max-width: 1100px){
    .SearchContainer>form{
        flex-direction: column;
    }
    .SearchContainer .Input{
        width: 100%;
    }

    .SearchContainer .SearchButton{
        width: 60%;
    }
    
}
