.ViewDetails{
   position: absolute;
   right: 50px;
   bottom: 20px;
   cursor: pointer;
}

.ViewDetails a{
    text-decoration: none;
 }


.ViewDetails p{
    color: #214D75;
    font-weight: bold;
    font-size: 1.1rem;
    margin: 0;
}

.ViewDetails img{
    width: 20px;
    height: 20px;
    margin-left: 10px;
}
.ViewDetails .Container{
    display: flex;
    flex-direction: row;
    align-items: center;
}