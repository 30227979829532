.NavigationItems{
    box-sizing: border-box;
    float: right;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    list-style: none;
    height: 100%;
    min-width: 30%;
}

.NavigationItems li{
    
    margin: auto 1.5rem;
    font-weight: bold;
    font-size: 1.2rem;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-basis: 0;
    cursor: pointer;
}


.NavigationItems li.Login, .NavigationItems li.Join{
    background-color: white;
    color: #214D75;
    border-radius: 10px;
    width: 100%;
    flex-grow: 1;
}

.NavigationItems li.Join{
    flex-grow: 3;
}

.NavigationItems li.Join>a, .NavigationItems li.Login>a{
    text-decoration: none;
    color: #214D75;;
    width: 100%;
    height: 100%;
    display: block;
    padding: 1rem 1.3rem;
}



.NavigationItems li.Help>a {
    text-decoration: none;
    color: white;
    padding: 0;
    display: inherit;
    flex-grow: 2;

}

.NavigationItems li.Login:hover, .NavigationItems li.Join:hover{
    background: lightgrey;
}

.NavigationItems li.Help:hover{
    color: lightgrey;
}

.NavigationItems a{
    text-align: center;
}

@media(max-width: 900px){
    .NavigationItems{
        flex-direction: column;
        height: auto;
        width: 100%;
        justify-content: center;
        float: center;
        padding: 0;
    }

    .NavigationItems li{
        margin: auto;
        font-size: 1.5rem;
    }
}