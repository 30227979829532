.LandingSearchContainer{
    width: 100%;
    height: auto;
    background: white;
    position: relative;
    margin-top: max(3rem, 4vw);
    box-sizing: border-box;
    padding: 2rem 0;

}
.LandingSearchContainer .Badge{
    position: absolute;
    box-sizing: border-box;
    top: -55px;
    left: 5%;
    background-color: white;
    padding: 1rem;
    border-radius: 10px;
}

.LandingSearchContainer .Title{
    padding: 0rem 10vw 4rem 4rem;
}

.LandingSearchContainer .Title > h1{
    text-align: left;
    color: #214D75;
    font-size: 2.3rem;
}

.LandingSearchContainer .Title>p{
    text-align: left;
}

.LandingSearchContainer .Title>p.TitleSubtitle{
    font-weight: bold;
    font-size: 1.2rem;
}

.LandingSearchContainer .Title>p.TitleDescription{
    font-weight: 500;
    font-size: 1.2rem;
    color: gray;
}


.LandingSearchContainer .Description{
    font-weight: bold;
    display: block;
    width: 100%;
    color: white;
    text-align: center;
}

.LandingSearchContainer > .Content {
    padding: 3rem 0rem 7.5rem 0;
    margin: 0;
    box-sizing: border-box;
    position: relative;
}

.LandingSearchContainer .BackgroundImage {
    z-index: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: none;
}

.LandingSearchContainer .Form{
    width: 70%;
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;   
    margin: auto;
}

.LandingSearchContainer .Input{
    margin: 1rem 1rem;
    box-sizing: border-box;
    min-width: 11rem;
    flex-grow: 1;
  
}

.LandingSearchContainer .HealthStatusContainer{
    flex-grow: 1;
    margin: 1rem 1rem;
    box-sizing: border-box;
    color: white;
}

.LandingSearchContainer .HealthStatusContainer .HelperText {
    font-size: .9rem;
    color: white;
    font-weight: 500;
}

.LandingSearchContainer .HealthStatusContainer .Label{
    color: white;
    font-size: 1.1rem;

}

.LandingSearchContainer .SearchButton{
    color: white;
    background: #DE6926;
    font-weight: bold;
    font-size: 1rem;
    padding: 1rem 2rem;
    border: none;
    min-width: 150px;
    border-radius: 10px;
    margin-left: 5%;
    outline: none;
    z-index: 2;
}

.LandingSearchContainer .SearchButton:hover{
    background: rgba(222, 105, 38, 0.5);
    cursor: pointer;
}

.SearchButton.Disabled{
    background: rgba(222, 105, 38, 0.5);
    pointer-events: none;  /* Makes button non clickable*/
}
.LandingSearchContainer .InputLabel{
    margin: 0;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    text-align: center;
}


@media(max-width: 64rem){
    .LandingSearchContainer>p{
        display: block;
        width: 100%;
        margin-top:0
    }

    .LandingSearchContainer{
        flex-wrap: wrap;
    }
}

@media (max-width: 1100px){
    .LandingSearchContainer .Form{
        flex-direction: column;
    }
    .LandingSearchContainer .Input{
        width: 100%;
    }

    .LandingSearchContainer .SearchButton{
        width: 60%;
    }
    
}
