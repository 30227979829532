.ErrorBox{
    background: rgba(253, 42,29,60%);
    text-align: left;
    padding: 0.8rem;
    box-sizing: border-box;
    border-radius: 5px;
}

.ErrorBox>p{
    margin: 0;
    padding: 0;
    font-weight: 500;
    color: black
}
