.ToolbarItems{
    list-style: none;
    display: flex;
    align-items: center; 
    justify-content: center;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    margin: 0;
}

@media(max-width: 900px){
    .ToolbarItems {
        flex-direction: column;
        width: 100%;
        height: auto;
        padding: 0;
    }

}