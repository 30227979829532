.Modal{
    width: 70%;
    position: fixed;
    left: 15%;
    top: 30%;
    z-index: 110;
    background: white;
    border-radius: 10px;
}

.Modal h2{
    padding: 3rem;
    color: #DE6926;
}