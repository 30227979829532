.NavigationBar{
    width: 100%;
    position: fixed;
    background-color: #214D75;
    display: flex;
    left: 0;
    top: 0;
    height: 5.1rem;
    align-items:center;
    justify-content: space-between;
    padding-left: 1rem;
    padding-right: 2rem;
    box-sizing: border-box;
    margin: 0;
    z-index: 20;
}

@media(max-width: 900px){
    .NavigationBar .NavigationItems{
        display: none;
    }
}
