.ButtonSpinner > button{
    margin-top: 1rem;
    width: 60%;
    color: white;
    background: #DE6926;
    font-weight: bold;
    font-size: 1.1rem;
    padding: 1rem 1rem;
    border: none;
    border-radius: 10px;
    outline: none;
    cursor: pointer;
    vertical-align: middle;
    box-sizing: border-box;
}

.ButtonSpinner > button > span{
    margin: 0px;
    margin-left: 15px;
    height: 20px;
    width: 20px;
    vertical-align: middle;
}

  
@keyframes rotate {
    0% {transform: rotate(0deg);}
    100% {transform: rotate(360deg);}
}
  
.spinner_border {

    display: inline-block;
    width: 1.7rem;
    height: 1.7rem;
    vertical-align: text-bottom;
    border: 0.25em solid currentColor;
    border-right-color: transparent;
    border-radius: 50%;
    animation: rotate .75s linear infinite;
}
  
.spinner_border_sm {
    width: 1rem;
    height: 1rem;
    border-width: 0.2em;
}
  