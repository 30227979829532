.InputContainer{
    display: flex;
    flex-direction: column;
    margin: auto;
    width: 80%;
    max-width: 550px;
}

.InputContainer>form{
    width: 100%;
}

.InputContainer .Input{
    background-color: white;
    width: 100%;
    margin: 0.5rem auto;
}

.InputContainer .ItemSelected  {
    background-color: white;
}

.InputContainer .SearchButton{
    color: white;
    background: #DE6926;
    font-weight: bold;
    font-size: 1rem;
    padding: 1rem 2rem;
    border: none;
    border-radius: 10px;
    margin-top: 1rem;
    outline: none;
    width: 30%;
    height: 3.3rem;
    min-width: 220px;
}

.InputContainer .SearchButton:hover{
    background: rgba(222, 105, 38, 0.5);
    cursor: pointer;
}

.InputContainer .SearchButton.Disabled{
    background: rgba(222, 105, 38, 0.5);
    pointer-events: none;  /* Makes button non clickable*/
}