.Boxes{
    display: none;
    padding: 4rem 2rem;
    background-color: white;
    display: grid;
    column-gap: 10vw;
    row-gap: 5vh;
    grid-template-columns: repeat(auto-fit, minmax(320px,auto));
    justify-items: center;
    justify-content: center;
}