.Toolbar{
    background-color: white;
    height: 4rem;
    width: 100%;
    position: fixed;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    top: 5.1rem;
    left: 0;
    z-index: 20;
}

.Toolbar > nav{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    height: 100%;
}

@media (max-width: 900px){
    .Toolbar{
        display: none;
    }
}